<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">Travel Insurance with Cruise Cover</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'CR-ST'" :coverType="'CR'"/>

				<div class="content">
					<h2>Do I need travel insurance with Cruise Cover?</h2>
					<p class="mb-5">
						Our Cruise Cover extension has been designed specifically to cover you for unexpected things which may happen on a cruise, for example, transport to hospital if you are taken ill or if you missed the departure time at one of your ports on your itinerary. 
						<br><br>
						Embarking on a cruise is a great way to travel, as you get to experience several different places on one holiday. Being onboard makes it especially important to take out cruise-specific travel insurance so you can enjoy exploring the seas, with the added peace of mind knowing that you are covered for any unexpected events.
						<br><br>
						Our Frequently Asked Questions should help provide you with the information you need when considering a cruise travel insurance policy.
					</p>

					<h2>What does travel insurance with Cruise Cover include?</h2>
					<p class="mb-5">
						The main benefit of getting cover for your cruise, is the cruise-specific benefits it provides. 
						<br>
					</p>
					<p class="mb-5">
						<ul>
							<li>Cover for Missed Port Departure up to £1,500
								<ul>
									<li>Cover for reasonable additional travel expenses necessarily incurred in reaching your next overseas port destination if you fail to check in on time for your outward departure as a result of: 
										<ul>
											<li>the vehicle you are travelling in has an accident or breaks down accident or</li>
											<li>your public transport is delayed</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>Skipped Port Benefit up to £1,000
								<ul>
									<li>Cover if your cruise operator are unable to dock at a designated port as per the pre-published itinerary as a result of:  
										<ul>
											<li>adverse weather conditions or</li>
											<li>timetable restrictions</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>Cabin Confinement cover up to £750
								<ul>
									<li>Cover if you are confined to your cabin or in a state hospital as an in- patient during the period of the trip due to illness or injury</li>
								</ul>
							</li>
							<li>Cover for Cruise Attire up to £1,000
								<ul>
									<li>You are covered up to the limit shown in the Summary of Cover for the value or repair of any of your formal cruise attire (not hired, loaned or entrusted to you), which is lost, stolen, damaged or destroyed (after making proper allowance for wear and tear and depreciation) limited to the single item or unreceipted item limit as shown on the Summary of Cover for any one item, pair or set of items.</li>
								</ul>
							</li>
							<li>Delayed Formal Cruise Attire up to £250
								<ul>
									<li>You are covered up to the limit shown in the Summary of Cover for the purchase or hire of replacement items if your formal cruise attire is delayed in reaching you on your outward journey for at least 24 hours from the time you boarded your ship. Receipts will be necessary in the event of a claim.</li>
								</ul>
							</li>
							<li>Cover for Shore Excursions up to £1,000
								<ul>
									<li>Cover for the unrecoverable costs of the excursions you have pre- paid but cannot go on due to: 
										<ul>
											<li>a medical practitioner has advised you not to go on the excursion because of your injury or illness (including being diagnosed with an epidemic or pandemic disease such as COVID-19) during your trip</li>
											<li>your cruise ship cannot make a scheduled stop at a port because of bad weather or other restriction</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>Cover for Cruise Interruption up to £1,500
								<ul>
									<li>Cover for the additional travel expenses you incur to reach the next port in order to re-join your cruise as a result of an illness or injury requiring treatment on dry land</li>
								</ul>
							</li>
							<li>Low / High Water Cover up to £2,000
								<ul>
									<li>You are covered up to the limit shown in the Summary of Cover for each occurrence where your river cruise is interrupted due to insufficient or excess water levels where your cruise operator:
										<ul>
											<li>provides only land-based alternative accommodation</li>
											<li>requires you to change ships.</li>
										</ul>
									</li>
								</ul>
							</li>
							<li>Denied Boarding Cover up to £250
								<ul>
									<li>Cover for food, communication expenses and additional travel expenses if you are denied boarding your cruise ship that you are scheduled to travel due to a suspicion that you have a contagious disease (including being diagnosed with an epidemic or pandemic diseases such as COVID-19).</li>
								</ul>
							</li>
						</ul>
					</p>

					<p class="mb-5">
						There are terms, conditions and exclusions on our Cruise policy, please take the time to read our <a href="/policy-wordings-mul">Policy Wording</a> which will provide you with the full details of cover.
					</p>

					<h2>Do I still need cruise travel insurance if I have an EHIC?</h2>
					<p class="mb-5">
						<strong>An EHIC or GHIC is not a replacement for cruise travel insurance</strong>. Although both
						the EHIC and GHIC will entitle you to receive emergency medical treatment abroad, they will not
						cover for repatriation if you need to return to the UK (medical repatriation) – something which
						is included as standard in many travel insurance policies.
						<br /><br />
						EHIC’s and GHIC’s will not cover you on the cruise ship but it will on dry land – even if you
						are just sailing around the Mediterranean. We offer travel insurance cover for cruises, which
						will not only cover your medical expenses, but also include cabin confinement, cover for your
						formal attire, missed port stops and missed excursions.
					</p>

					<h2>Will the destinations on my itinerary have an impact on the cost of my travel insurance?</h2>
					<p class="mb-5">
						Depending on where you are travelling to, your destinations may well have an impact on your travel insurance premium. 
						<br>
						For example, the following countries all have a high proportion of private health care compared to state provided health care. Therefore, the cost of travel insurance may well increase if you are travelling to:
					</p>
					<p class="mb-5">
						<ul>
							<li>North and Central America</li>
							<li>China and Hong Kong</li>
						</ul>
					</p>
					<p class="mb-5">
						Due to this, the costs of travel insurance may be more if you are travelling further afield. To give you an idea of the costs of medical care and repatriation, a stomach bug or infection treated in the USA, with return flights have seen costs in the region of £100,000. 
					</p>

					<h2>What if I have pre-existing medical conditions?</h2>
					<p class="mb-5">
						It is important to declare any pre-existing medical conditions to our medical screening team to ensure you have full cover for your cruise. If you do not disclose your conditions, and you have a medical emergency abroad, which is linked to your condition, then the claim could be refused. Any conditions, such as, high blood pressure, or diabetes, are best to disclose, as you could be risking thousands if you choose not to. 
						<br><br>
						For more information on cover for pre-existing medical conditions, <a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">click here</a>.
					</p>

					<h2>Our top tips when choosing cruise cover</h2>
					<p class="mb-5">
						<ul>
							<li>Ensure you choose a policy with the right cancellation amount i.e. covers the cost of your trip, so that you can get a refund should you need to cancel. </li>
							<li>Choose the right cover limits for you, for example, if you are travelling to Europe, the minimum amount of medical cover recommended is £1 Million, and £2 Million for the rest of the world. Our cruise policy’s medical expenses limit is £10,000,000. </li>
							<li>Cover for medical conditions is really important. We consider all medical conditions so ensure you disclose these to ensure you are fully covered.</li>
						</ul>
					</p>

					
				</div>
				
				<InfoButtonOptions :policyType="'CR-ST'" :coverType="'CR'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'CruiseTravelInsurance',
	data() {
		return {

		}
	},
	methods: {

	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
